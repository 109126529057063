<template>
  <div
    class="home-gradient-absolute"
    data-animate="fadeIn"
    data-animate-delay="1"
  >
    <div
      class="home-gradient-sticky"
      :class="{ 'with-subnav': story?.hasSubnav }"
    >
      <div class="main-hero-gradient-box"></div>
    </div>
  </div>
</template>

<script setup>
defineProps({
  subnav: Boolean,
});

const { $store } = useNuxtApp();
const story = computed(() => $store.getters.getStory);
</script>

<style scoped>
.home-gradient-sticky.with-subnav {
  margin-top: -110px;
}
</style>
